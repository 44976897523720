/** @jsx jsx */
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { arrayOf, oneOf, string } from 'prop-types'
import { Container, Heading, jsx, Text } from 'theme-ui'

import ButtonList from '~/components/ButtonList'
import IntroTransition from '~/components/IntroTransition'
import Section from '~/components/Section'
import renderLink from '~/content/renderLink'
import renderRichText from '~/content/renderRichText'
import ContentLeftLayout from '~/layout/ContentLeftLayout'
import {
  ContentfulImagePropType,
  ContentfulImageType,
  ContentfulRichTextPropType,
  ContentfulRichTextType,
  LinkPropType,
  LinkType,
} from '~/types'

const DISPLAY_NAME = 'CardLargeSection'

const POSITION_TO_FLEX_POSITION_MAP = {
  Left: 'flex-start',
  Center: 'center',
  Right: 'flex-end',
  Top: 'flex-start',
  Bottom: 'flex-end',
}

const POSITION_TO_TEXT_ALIGN_MAP = {
  Left: 'left',
  Center: 'center',
  Right: 'right',
}

const PROP_TYPES = {
  backgroundImageDesktop: ContentfulImagePropType,
  backgroundImageMobile: ContentfulImagePropType,
  body: ContentfulRichTextPropType.isRequired,
  contentPositionX: oneOf(['Left', 'Center', 'Right']),
  contentPositionY: oneOf(['Top', 'Center', 'Bottom']),
  heading: string.isRequired,
  kicker: string,
  links: arrayOf(LinkPropType),
  textColorScheme: oneOf(['Light', 'Dark']),
}

interface PropTypes {
  backgroundImageDesktop: ContentfulImageType,
  backgroundImageMobile: ContentfulImageType,
  body: ContentfulRichTextType,
  contentPositionX: 'Left' | 'Center' | 'Right',
  contentPositionY: 'Top' | 'Center' | 'Bottom',
  elementId?: string,
  heading: string,
  kicker?: string,
  links: LinkType[],
  textColorScheme: 'Light' | 'Dark',
}

const Component = ({
  backgroundImageDesktop,
  backgroundImageMobile,
  body,
  contentPositionX = 'Left',
  contentPositionY = 'Top',
  elementId,
  heading,
  kicker,
  links,
  textColorScheme = 'Light',
}: PropTypes) => {
  const images = withArtDirection(getImage(backgroundImageDesktop.localFile), [{
    media: '(max-width: 767px)',
    image: getImage(backgroundImageMobile.localFile),
  }])
  return (
    <Section
      sx={{ position: 'relative' }}
      textColorScheme={textColorScheme}
      id={elementId}
    >
      <Container sx={{ display: ['contents', null, 'block'] }}>
        <div sx={{ position: 'relative' }}>
          <GatsbyImage
            alt=""
            aria-hidden
            draggable={false}
            image={images}
            sx={{
              borderRadius: [null, null, 'large'],
              position: ['absolute', null, 'inherit'],
              top: [0, null, 'unset'],
              bottom: [0, null, 'unset'],
              left: [0, null, 'unset'],
              right: [0, null, 'unset'],
            }}
          />
          <div
            sx={{
              position: [null, null, 'absolute'],
              top: [null, null, 0],
              bottom: [null, null, 0],
              left: [null, null, 0],
              right: [null, null, 0],
              paddingY: 3,
            }}
          >
            <Container
              sx={{
                minHeight: [null, null, '100%'],
                display: 'flex',
              }}
            >
              <div
                sx={{
                  alignItems: [
                    null,
                    POSITION_TO_FLEX_POSITION_MAP[contentPositionY],
                  ],
                  display: [null, 'flex'],
                  flexGrow: 1,
                  justifyContent: [
                    null,
                    POSITION_TO_FLEX_POSITION_MAP[contentPositionX],
                  ],
                  minHeight: [null, '100%'],
                  textAlign: [
                    null,
                    POSITION_TO_TEXT_ALIGN_MAP[contentPositionX],
                  ],
                }}
              >
                <ContentLeftLayout>
                  <IntroTransition>
                    <div>
                      {kicker && (
                        <Text variant="bodyUppercase">
                          {kicker}
                        </Text>
                      )}
                      <Heading
                        sx={{
                          variant: 'text.headingHero',
                          marginBottom: 1,
                        }}
                      >
                        {heading}
                      </Heading>
                      {renderRichText(body, { style: 'prospect' })}
                      {!!links?.length && (
                        <ButtonList
                          sx={{
                            marginTop: 2,
                            alignItems: [
                              null,
                              'center',
                            ],
                            justifyContent: [
                              null,
                              POSITION_TO_FLEX_POSITION_MAP[contentPositionX],
                            ],
                          }}
                        >
                          {links.map(({ id, ...link }) => (
                            <li key={id}>
                              {renderLink(link, { scheme: textColorScheme })}
                            </li>
                          ))}
                        </ButtonList>
                      )}
                    </div>
                  </IntroTransition>
                </ContentLeftLayout>
              </div>
            </Container>
          </div>
        </div>
      </Container>
    </Section>
  )
}

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
